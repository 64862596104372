<div class="rounded" id="sidebar-wrapper">
	<div class="text-center pt-3 mb-5 border-bottom pb-3">
		<span style="font-size: 22px; font-weight: bold;">ADMIN &nbsp;</span>

		<span *ngIf="button_in_sidebar == true">
			<button class="btn text-light" style="background-color: #8261ee;" id="menu-toggle-side-bar" (click)="toggleMenuSideBar();">
				<strong style="font-size: 20px;">&#8801;</strong>
			</button>				
		</span>
	</div>
	<div class="list-group list-group-flush contenedor-link">

		<a class="sidebar-link rounded mt-2 mb-2 link-dashboard" [routerLink]="['/dashboard/admin']">
			<i class="fa fa-home" aria-hidden="true" style="color: #2196f3;"></i> {{ 'nav.menu_admin_dashboard' | translate }}
		</a>

		<hr class="mt-0 mb-0">
		<a class="sidebar-link rounded mt-2 mb-2 link-pasajeros" [routerLink]="['/company/admin']">
			<i class="fa fa-search" aria-hidden="true" style="color: #ff5722;"></i> {{ 'nav.menu_admin_company' | translate }}
		</a>

		<hr class="mt-0 mb-0">
		<a class="sidebar-link rounded mt-2 mb-2 link-nuevo" [routerLink]="['/company/admin/news']">
			<i class="fa fa-plus" aria-hidden="true" style="color: #e91e63;"></i> {{ 'nav.menu_admin_new_company' | translate }}
		</a>										

		<hr class="mt-0 mb-0">
		<a class="sidebar-link rounded mt-2 mb-2 link-comentarios" [routerLink]="['/comment/admin']">
			<i class="fa fa-comments" aria-hidden="true" style="color: #9c27b0"></i> {{ 'nav.menu_admin_comments' | translate }}
		</a>	

	</div>
</div>