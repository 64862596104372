import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

	@Input() button_in_sidebar: boolean = false;
	@Output() change_button = new EventEmitter<boolean>();

 	//public show_in_menu: boolean;
 	public screenHeight;
 	public screenWidth;

 	constructor(
		public translate: TranslateService
	){
		this.loadLanguage();
 	}


	ngOnInit(): void {
	} 	

	ngDoCheck(){
		this.loadLanguage();
	}	

	loadLanguage(){
		let lang = localStorage.getItem('lang');
		if(lang == 'es'){
			this.translate.use('es');
		}else{
			this.translate.use('en');
		}
	} 	

	toggleMenuSideBar() {
		var element = document.getElementById("wrapper");
		element.classList.toggle("toggled");
		this.button_in_sidebar = false;
		this.change_button.emit(false);
		localStorage.setItem('changeMLeft', '0');
	}   

}
