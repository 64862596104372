import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ShowAlertService } from './../_services/general/show_alert.service';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
  providers: [ShowAlertService]
})
export class ErrorComponent implements OnInit {

	public time: number = 5;

	constructor(
		private _showAlertService: ShowAlertService,
		private _router: Router,
		private _route: ActivatedRoute	
	) {
		/*this._showAlertService.error_alert_timer('Pagina incorrecta!', 'Se cerrara en');*/
	}

	ngOnInit(): void {
		this._router.navigate(['/']);
	}

}