import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
	},	
	{
		path: 'auth',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
	}, 	  
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
	}, 	
	{
		path: 'passenger',
		loadChildren: () => import('./passenger/passenger.module').then(m => m.PassengerModule)
	},
	{
		path: 'company',
		loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
	},
	{
		path: 'calendar',
		loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
	},
	{
		path: 'comment',
		loadChildren: () => import('./comment/comment.module').then(m => m.CommentModule)
	},	 	 	 	  	 
	{
		path: '**', 
		component: ErrorComponent
	}, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
