import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';
import { MainComponent } from './component/header/main/main.component';
import { AdminComponent } from './component/header/admin/admin.component';
import { UserComponent } from './component/header/user/user.component';
import { ManagerComponent } from './component/header/manager/manager.component';
import { UtilsService } from './_services/system/utils.service';


import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AdsenseModule } from 'ng2-adsense';

export function HttpLoaderFactory( http: HttpClient ){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    MainComponent,
    AdminComponent,
    UserComponent,
    ManagerComponent,
  ],
  imports: [
    SweetAlert2Module,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader:{
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true,
    }),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-3484230860252646',
      adSlot: 6632067080,
    }),    
    BrowserAnimationsModule
  ],
  providers: [UtilsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
