import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

	public lang;

	constructor(
		private translate: TranslateService
	){
		this.loadLanguage();
	}

	ngOnInit(): void {
	}

	ngDoCheck(){
		this.loadLanguage();
	}

	loadLanguage(){
		let lang = localStorage.getItem('lang');
		if(lang == 'es'){
			this.lang = 'es';
			this.translate.use('es');
		}else{
			this.lang = 'en';
			this.translate.use('en');
		}
	} 		

	changeLanguage(lang: string){
		if(lang == 'es'){
			localStorage.setItem('lang', 'es');	
		}else{
			localStorage.setItem('lang', 'en');	
		}
	}	

}
