import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

	@Input() button_in_sidebar: boolean = false;
	@Output() change_button = new EventEmitter<boolean>();

 	//public show_in_menu: boolean;
 	public screenHeight;
 	public screenWidth;
 	public identity;

 	constructor(
 		public translate: TranslateService
 	){
 		this.loadLanguage();
 	}


	ngOnInit(): void {
		this.loadIdentity();
	} 	

	ngDoCheck(){
		this.loadLanguage();
	}

	loadIdentity(){
		let identity = JSON.parse(localStorage.getItem('identity'));
		this.identity = identity;
	}

	loadLanguage(){
		let lang = localStorage.getItem('lang');
		if(lang == 'es'){
			this.translate.use('es');
		}else{
			this.translate.use('en');
		}
	} 		

	toggleMenuSideBar() {
		var element = document.getElementById("wrapper");
		element.classList.toggle("toggled");
		this.button_in_sidebar = false;
		this.change_button.emit(false);
		localStorage.setItem('changeMLeft', '0');
	}    

}
