import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UtilsService } from '../system/utils.service';
import { Global } from '../global';

import Swal from 'sweetalert2'

@Injectable()
export class ShowAlertService {


	public url: string;
	public identity;
	public token;


	constructor(
		public _http: HttpClient,
		private _router: Router,
		private _route: ActivatedRoute,
		private _utilsService: UtilsService	
	) {
		this.url = _utilsService.getDomain();
	}



	success_alert(title, text){
		Swal.fire({
		  icon: 'success',
		  title: title,
		  text: text
		})		
	}	

	error_alert(error){
		let text = JSON.stringify(error.error.message);

		var re = /,/g;
		text = text.replace(re, '\n\t');

		var re = /"/g;
		text = text.replace(re, '');

		text = text.replace('{', '');
		text = text.replace('}', '');

		text = text.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,' ');

		Swal.fire({
		  icon: 'error',
		  title: 'Oops...',
		  text: text
		})		
	}	

	error_alert_direct_message(msg){
		Swal.fire({
		  icon: 'error',
		  title: 'Oops...',
		  text: msg
		})		
	}			

	error_toast(msg){
		const Toast = Swal.mixin({
		  	toast: true,
		  	position: 'bottom-start',
		  	//position: 'top-end',
		  	showConfirmButton: false,
		  	timer: 3000,
		  	timerProgressBar: true,
		  	didOpen: (toast) => {
		    	toast.addEventListener('mouseenter', Swal.stopTimer)
		    	toast.addEventListener('mouseleave', Swal.resumeTimer)
		  	}
		})

		Toast.fire({
		  	icon: 'error',
		  	title: msg
		})		

	}

	simple_msg(msg){
		Swal.fire(msg);
	}

	error_alert_timer(title, html){

		let timerInterval
		Swal.fire({
			title: title,
			html: html + ' <b></b> milliseconds.',
			timer: 2000,
			timerProgressBar: true,
			didOpen: () => {
				Swal.showLoading()
				timerInterval = setInterval(() => {
					const content = Swal.getContent()
					if (content) {
						const b = content.querySelector('b')
						if (b) {
							b.textContent = Swal.getTimerLeft().toString()
						}
					}
				}, 100)
			},
		willClose: () => {
			clearInterval(timerInterval)
		}
		}).then((result) => {
			/* Read more about handling dismissals below */
			if (result.dismiss === Swal.DismissReason.timer) {
				console.log('I was closed by the timer')
			}
		})

	}	

}