<app-main *ngIf="identity.role == 0"></app-main>
<router-outlet *ngIf="identity.role == 0"></router-outlet>

<div class="d-flex" id="wrapper" *ngIf="identity.role >= 1">

	<app-admin [button_in_sidebar]="show_in_menu" (change_button)="change_button($event)" *ngIf="identity.role == 1"></app-admin>
	<app-user [button_in_sidebar]="show_in_menu" (change_button)="change_button($event)" *ngIf="identity.role == 2"></app-user>
	<app-manager [button_in_sidebar]="show_in_menu" (change_button)="change_button($event)" *ngIf="identity.role == 3"></app-manager>
	

	<!-- Page Content -->
	<div id="page-content-wrapper" style="{{ mleft }}">
		<!--<nav class="navbar navbar-expand-lg navbar-light border-bottom menu-superior">
			<button class="btn btn-primary" id="menu-toggle" (click)="toggleMenu();" *ngIf="show_in_menu == false" style="margin-left: 20px;">
				<strong style="font-size: 20px; color: white;">&#8801;</strong>
			</button>
			<div class="" style="position: absolute; right: 0px;" id="navbarSupportedContent">
				<ul class="navbar-nav mt-2 mt-lg-0">
			        <li class="nav-item dropdown" style="margin-right: 80px;">
			          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
			            Ajustes
			          </a>
			          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
			            <li><a class="dropdown-item text-center" [routerLink]="['/auth/disconect/1']">Cerrar sesion</a></li>
			          </ul>
			        </li>
				</ul>
			</div>			
		</nav>-->
		<nav class="navbar navbar-expand-lg navbar-light border-bottom menu-superior">
			<div class="container-fluid">
				<button class="btn" id="menu-toggle" (click)="toggleMenu();" *ngIf="show_in_menu == false" style="margin-left: 20px; background-color: #8261ee;">
					<strong style="font-size: 20px; color: white;">&#8801;</strong>
				</button>
				<div class="d-flex" id="navbarNavDropdown">

					
					<!-- IDIOMA ESPAÑOL -->
					<ul class="navbar-nav d-flex" *ngIf="lang == 'es'">
						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="">
								<img src="https://flagcdn.com/w20/es.png"
										  srcset="https://flagcdn.com/w40/es.png 2x"
										  width="20"
										  alt="Spain"> &nbsp;	
								{{ 'nav.nav_language' | translate }}
							</a>
							<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
								<li>
									<a class="dropdown-item lang-select" (click)="changeLanguage('es')">
										<img
										  src="https://flagcdn.com/w20/es.png"
										  srcset="https://flagcdn.com/w40/es.png 2x"
										  width="20"
										  alt="Spain">
										  &nbsp; {{ 'nav.nav_spanish' | translate }}
									</a>
								</li>
								<li>
									<a class="dropdown-item lang-select" (click)="changeLanguage('en')">
										<img
										  src="https://flagcdn.com/w20/us.png"
										  srcset="https://flagcdn.com/w40/us.png 2x"
										  width="20"
										  alt="Estados unidos">
										  &nbsp; {{ 'nav.nav_english' | translate }}
									</a>
								</li>
							</ul>				
						</li>
					</ul>
					<!-- FIN IDIOMA ESPAÑOL -->


					<!-- IDIOMA INGLES -->
					<ul class="navbar-nav d-flex" *ngIf="lang == 'en'">
						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="">
									<img src="https://flagcdn.com/w20/us.png"
										  srcset="https://flagcdn.com/w40/us.png 2x"
										  width="20"
										  alt="Spain"> &nbsp;						
								{{ 'nav.nav_language' | translate }}
							</a>
							<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
								<li>
									<a class="dropdown-item lang-select" (click)="changeLanguage('en')">
										<img
										  src="https://flagcdn.com/w20/us.png"
										  srcset="https://flagcdn.com/w40/us.png 2x"
										  width="20"
										  alt="Estados unidos">
										  &nbsp; {{ 'nav.nav_english' | translate }}
									</a>
								</li>						
								<li>
									<a class="dropdown-item lang-select" (click)="changeLanguage('es')">
										<img
										  src="https://flagcdn.com/w20/es.png"
										  srcset="https://flagcdn.com/w40/es.png 2x"
										  width="20"
										  alt="Spain">
										  &nbsp; {{ 'nav.nav_spanish' | translate }}
									</a>
								</li>
							</ul>					
						</li>
					</ul>	
					<!-- FIN IDIOMA INGLES -->		
					

					<ul class="navbar-nav" style="margin-right: 75px;">
						<li class="nav-item dropdown" style="overflow: none;">
							<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								{{ 'nav.menu_other' | translate }}
							</a>							
							<ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style="overflow: none;">
								<li *ngIf="identity.role == 2">
									<a class="dropdown-item text-center" [routerLink]="['/company/profile']">
										{{ 'nav.menu_settings' | translate }}
									</a>
								</li>
								<li><a class="dropdown-item text-center" [routerLink]="['/auth/disconect/1']">{{ 'nav.menu_signout' | translate }}</a></li>
							</ul>
						</li>
					</ul>
				</div>				
			</div>
		</nav>
		<router-outlet></router-outlet>
	</div>	

</div>


<div class="container mt-5 mb-3 pb-3" *ngIf="identity.role == 0">
	<div class="row text-center">
		<p class="mt-0 mb-0 text-secondary">Mail: info@qualifyguest.com</p>
		<p class="mt-0 mb-0 text-secondary">Phone: +569 95480944</p>
		<p class="mt-0 mb-0 text-secondary">La Serena, Coquimbo</p>
		<p class="mt-0 mb-0 text-secondary">All rights reserved</p>		
	</div>
</div>
