import { Component } from '@angular/core';
import { HostListener } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

import { UserService } from './_services/general/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [UserService]
})
export class AppComponent {

 	title = 'Qualifyguest';

 	public identity;
	public token:string;
	public lang;

 	public show_in_menu: boolean;
 	public screenHeight;
 	public screenWidth;

 	//estilo para cambiar el margin left del contenedor de la pagina
 	public mleft;
 	public changeMLeft;


 	constructor(
 		private _userService: UserService,
 		public translate: TranslateService
 	){
 		this._userService.move_to_other_component();
		this.loadLanguage();
 		this.show_in_menu = false;
 		this.load_user();
 		this._userService.check_token_date(); 
 		this.checkWidth();
 		//localStorage.setItem('changeMLeft', '0');
 	}
	

	ngOnInit(): void {
		setInterval(() => {
			this.changeMLeft = localStorage.getItem('changeMLeft');
			if(this.changeMLeft == '0'){
				this.mleft = "margin-left: 0px; -webkit-transition: margin .25s ease-out;-moz-transition: margin .25s ease-out;-o-transition: margin .25s ease-out;transition: margin .25s ease-out;";
			}else{
				this.mleft = "margin-left: 240px; -webkit-transition: margin .25s ease-out;-moz-transition: margin .25s ease-out;-o-transition: margin .25s ease-out;transition: margin .25s ease-out;";
			}		
        }, 100);
	}

	ngDoCheck(){
		this.load_user();
		this.loadLanguage();
	}


	loadLanguage(){
		let lang = localStorage.getItem('lang');
		if(lang == 'es'){
			this.lang = 'es';
			this.translate.use('es');
		}else{
			this.lang = 'en';
			this.translate.use('en');
		}
	} 	

	load_user(){
		this.identity = this._userService.get_identity();
		this.token = this._userService.get_token();  	
	} 	

	toggleMenua() {

		this.onResize();
		var element = document.getElementById("wrapper");
		element.classList.toggle("toggled");

		// SE COMPRUEBA SI SE TIENE QUE MANTENER EL MARGIN LEFT O MODIFICARLO, YA QUE EL CONTENEDOR WRAPPER ESTA EN FIXED
		if(this.changeMLeft == 1){

			//console.log("1")
			
			if(this.screenWidth >= 768){
				//console.log("1 - <= 768")
				this.changeMLeft = 0;
				this.mleft = "margin-left: 240px; -webkit-transition: margin .25s ease-out;-moz-transition: margin .25s ease-out;-o-transition: margin .25s ease-out;transition: margin .25s ease-out;";
			}else{
				//console.log("1 - > 768")
				this.changeMLeft = 0;
				this.mleft = "margin-left: 240px; -webkit-transition: margin .25s ease-out;-moz-transition: margin .25s ease-out;-o-transition: margin .25s ease-out;transition: margin .25s ease-out;";
			}

		}else{
			//console.log("0")
			if(this.screenWidth >= 768){
				//console.log("0 - <= 768")
				this.changeMLeft = 1;
				this.mleft = "margin-left: 240px; -webkit-transition: margin .25s ease-out;-moz-transition: margin .25s ease-out;-o-transition: margin .25s ease-out;transition: margin .25s ease-out;";
			}else{
				//console.log("0 - > 768")
				this.changeMLeft = 1;
				this.mleft = "margin-left: 0px; -webkit-transition: margin .25s ease-out;-moz-transition: margin .25s ease-out;-o-transition: margin .25s ease-out;transition: margin .25s ease-out;";
			}
		}

		if(this.screenWidth <= 300){
			this.show_in_menu = true;
		}

	}


	toggleMenu() {

		this.onResize();
		//this.changeMLeft = localStorage.getItem('changeMLeft'); 
		if(this.screenWidth <= 300){
			this.show_in_menu = true;
		}

		var element = document.getElementById("wrapper");
		element.classList.toggle("toggled");

		if(this.changeMLeft == '0'){
			this.mleft240();
		}else{
			this.mleft0();
		}

		//alert(this.changeMLeft);

	}

	mleft0(){
		this.mleft = "margin-left: 0px; -webkit-transition: margin .25s ease-out;-moz-transition: margin .25s ease-out;-o-transition: margin .25s ease-out;transition: margin .25s ease-out;";
		localStorage.setItem('changeMLeft', '0');
	}

	mleft240(){
		this.mleft = "margin-left: 240px; -webkit-transition: margin .25s ease-out;-moz-transition: margin .25s ease-out;-o-transition: margin .25s ease-out;transition: margin .25s ease-out;";
		localStorage.setItem('changeMLeft', '240');
	}	


	@HostListener('window:resize', ['$event'])
	onResize(event?) {
	   this.screenHeight = window.innerHeight;
	   this.screenWidth = window.innerWidth;
	}	 


	change_button(request) {
		this.show_in_menu = request;
	}

	checkWidth(){
		this.onResize();
		if(this.screenWidth <= 768){
			this.mleft = "margin-left: 0;";
		}else{
			this.mleft = "margin-left: 240px;";
		}	

		if(this.screenWidth <= 768){
			this.changeMLeft = '0';
			localStorage.setItem('changeMLeft', '0');
		}else{
			this.changeMLeft = '240';
			localStorage.setItem('changeMLeft', '240');
		}

	}

	changeLanguage(lang: string){
		if(lang == 'es'){
			localStorage.setItem('lang', 'es');	
		}else{
			localStorage.setItem('lang', 'en');	
		}
	}

}
