import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UtilsService } from '../system/utils.service';
import { Global } from '../global';

@Injectable()
export class UserService {

	public url: string;
	public identity;
	public token;


	constructor(
		public _http: HttpClient,
		private _router: Router,
		private _route: ActivatedRoute,
		private _utilsService: UtilsService		
	) {
		this.url = _utilsService.getDomain();
	}



	/*
		GENERAL METHODS NOT TOKEN REQUIRED
	*/

	signup(login): Observable<any> {

		let json = JSON.stringify(login);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(this.url + 'v1/login', params, { headers: headers });
	}

	register(register): Observable<any> {

		let json = JSON.stringify(register);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(this.url + 'v1/register', params, { headers: headers });
	}

	contact(contact): Observable<any> {

		let json = JSON.stringify(contact);
		let params = 'json=' + json;

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(this.url + 'v1/contact', params, { headers: headers });
	}		

	get_identity() {

		let identity = JSON.parse(localStorage.getItem('identity'));
		if(identity && identity != 'undefined'){
			this.identity = identity;
		}else{
			this.identity = null;
			localStorage.setItem('identity', JSON.stringify({"sub":0,"email":"","name":"","surname":"","role":0,"iat":0,"exp":0}));
		}
		return this.identity;

	}

	get_token() {

		let token = localStorage.getItem('token');
		if(token && token != 'undefined'){
			this.token = token;
		}else{
			this.token = null;
			localStorage.setItem('token', null);
		}
		return this.token;

	}

	forgot_password(user): Observable<any> {

		let json = JSON.stringify(user);
		let params = "json=" + json;

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(this.url + 'public/recover-password', params, { headers: headers });

	}

	change_password(user): Observable<any> {

		let json = JSON.stringify(user);
		let params = "json=" + json;

		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
		return this._http.post(this.url + 'public/change-password', params, { headers: headers });

	}	

	remove_data(route){

		localStorage.setItem('identity', JSON.stringify({"sub":0,"email":"","name":"","surname":"","role":0,"iat":0,"exp":0}));
		localStorage.setItem('token', 'null');
		localStorage.setItem('exp', 'null');
		localStorage.setItem('logout', '1');			
		this._router.navigate(['/'+route]);

	}


	check_token_date(){

		let expiration 	= localStorage.getItem('exp');
		let token 		= localStorage.getItem('token');
		let identity    = localStorage.getItem('identity');

		if(expiration != 'null'){

			let expiration_date = new Date(expiration);
			var date = new Date();
			if(expiration_date.getTime() >= date.getTime()){
			  	
			  	if(identity == '{"sub":0,"email":"","name":"","surname":"","role":0,"iat":0,"exp":0}'){
			  		this.remove_data('');
			  	}

			}else{
				//expired
				this.remove_data('');
			}

		}

		if(token != 'null' && expiration == 'null'){
			this.remove_data('login');
		}

	}	


	move_to_other_component(){

		this.check_token_date();
		let identity = this.get_identity();
		let urlc = this._router["url"];

		if(urlc == "" || urlc == "/" || urlc == "/about" || urlc == "/contact" || urlc == "/auth" || urlc == "/auth/register"){
			if(identity != null){
	            switch (this.identity.role) {
	            	case 1:
	            		this._router.navigate(['/dashboard/admin']);
	            		break;
	            	case 2:
	            		this._router.navigate(['/passenger']);							            					            							            	
	            }
			}	
		}
	
	}


	/*
		END 
	*/




	/*
		TOKEN REQUIRED
	*/


	get_users_company(token): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
										.set('Authorization', token);
		return this._http.get(this.url + 'v1/company/users', { headers: headers });			
	}

	get_user_company(token, id): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
										.set('Authorization', token);
		return this._http.get(this.url + 'v1/company/user/' + id, { headers: headers });			
	}	

	update_user(params, id, token): Observable<any>{
		let json = JSON.stringify(params);
		let jsonparams = 'json=' + json;
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
										.set('Authorization', token);
		return this._http.put(this.url + 'v1/company/user/' + id, jsonparams, { headers: headers });
	}

	new_user(params, token): Observable<any>{
		let json = JSON.stringify(params);
		let jsonparams = 'json=' + json;
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
										.set('Authorization', token);
		return this._http.post(this.url + 'v1/company/register', jsonparams, { headers: headers });
	}		


	load_profile(token): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
										.set('Authorization', token);
		return this._http.get(this.url + 'v1/user', { headers: headers });			
	}	


	update_profile(params, token): Observable<any>{
		let json = JSON.stringify(params);
		let jsonparams = 'json=' + json;
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
										.set('Authorization', token);
		return this._http.put(this.url + 'v1/user', jsonparams, { headers: headers });
	}	


	check_activity(token): Observable<any> {
		let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
										.set('Authorization', token);
		return this._http.get(this.url + 'v1/check-activity', { headers: headers });			
	}	



	/*
		END 
	*/

}