<nav class="navbar navbar-expand-sm navbar-light bg-light">
	<div class="container-fluid">
		<a class="navbar-brand" href="#">
			<h1 style="font-size: 22px; font-weight: bold; color: #5B35D9;">Qualifyguest.com</h1>
		</a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			
			<!-- CONTENIDO PRINCIPAL -->
			<ul class="navbar-nav me-auto mb-2 mb-lg-0">
				<li class="nav-item">
					<a class="nav-link" href="#" routerLink="/">{{ 'nav.nav_home' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="#" routerLink="/about">{{ 'nav.nav_about' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="#" routerLink="/contact">{{ 'nav.nav_contact' | translate }}</a>
				</li>				
				<!--
				<li class="nav-item">
					<a class="nav-link" href="#" tabindex="-1" routerLink="/price">{{ 'nav.nav_price' | translate }}</a>
				</li>
				-->
			</ul>
			<!-- FIN CONTENIDO PRINCIPAL -->


			<!-- IDIOMA ESPAÑOL -->
			<ul class="navbar-nav d-flex" *ngIf="lang == 'es'">
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="">
						<img src="https://flagcdn.com/w20/es.png"
								  srcset="https://flagcdn.com/w40/es.png 2x"
								  width="20"
								  alt="Spain"> &nbsp;	
						{{ 'nav.nav_language' | translate }}
					</a>
					<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
						<li>
							<a class="dropdown-item lang-select" (click)="changeLanguage('es')">
								<img
								  src="https://flagcdn.com/w20/es.png"
								  srcset="https://flagcdn.com/w40/es.png 2x"
								  width="20"
								  alt="Spain">
								  &nbsp; {{ 'nav.nav_spanish' | translate }}
							</a>
						</li>
						<li>
							<a class="dropdown-item lang-select" (click)="changeLanguage('en')">
								<img
								  src="https://flagcdn.com/w20/us.png"
								  srcset="https://flagcdn.com/w40/us.png 2x"
								  width="20"
								  alt="Estados unidos">
								  &nbsp; {{ 'nav.nav_english' | translate }}
							</a>
						</li>
					</ul>				
				</li>
			</ul>
			<!-- FIN IDIOMA ESPAÑOL -->


			<!-- IDIOMA INGLES -->
			<ul class="navbar-nav d-flex" *ngIf="lang == 'en'">
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="">
							<img src="https://flagcdn.com/w20/us.png"
								  srcset="https://flagcdn.com/w40/us.png 2x"
								  width="20"
								  alt="Spain"> &nbsp;						
						{{ 'nav.nav_language' | translate }}
					</a>
					<ul class="dropdown-menu" aria-labelledby="navbarDropdown">
						<li>
							<a class="dropdown-item lang-select" (click)="changeLanguage('en')">
								<img
								  src="https://flagcdn.com/w20/us.png"
								  srcset="https://flagcdn.com/w40/us.png 2x"
								  width="20"
								  alt="Estados unidos">
								  &nbsp; {{ 'nav.nav_english' | translate }}
							</a>
						</li>						
						<li>
							<a class="dropdown-item lang-select" (click)="changeLanguage('es')">
								<img
								  src="https://flagcdn.com/w20/es.png"
								  srcset="https://flagcdn.com/w40/es.png 2x"
								  width="20"
								  alt="Spain">
								  &nbsp; {{ 'nav.nav_spanish' | translate }}
							</a>
						</li>
					</ul>					
				</li>
			</ul>	
			<!-- FIN IDIOMA INGLES -->


			<!-- REGISTRO Y LOGIN -->
			<ul class="navbar-nav d-flex">
				<li class="nav-item">
					<a class="nav-link active" aria-current="page" href="#" routerLink="/auth">{{ 'nav.nav_login' | translate }}</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="#" routerLink="/auth/register">{{ 'nav.nav_register' | translate }}</a>
				</li>
			</ul>
			<!-- FIN REGISTRO Y LOGIN -->

		</div>
	</div>
</nav>

