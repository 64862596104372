import { Injectable } from '@angular/core';
import { Global } from '../global';

@Injectable()
export class UtilsService {

	public url: string;
	public domainServer: string = 'qualifyguest.com';
	public testingServer: string = 'testing.qualifyguest.com';

	constructor(
	) {
		this.url = Global.url_production;
	}


	getDomain(){
		let domain = '';
		domain = this.checkDomain();
		return domain;
	}

	checkDomain(){

		let domain = window.location.hostname;
		if(domain == this.domainServer || domain == this.testingServer){
			this.url = Global.url_production;
		}else{
			this.url = Global.url_local;
		}
		return this.url;

	}

}